import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const tileProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {

        params.data.show = !params.data.show

        if(params.data.main_image){
            const body  = new FormData();
            body.append(params.data.id + params.data.main_image.title.substring(params.data.main_image.title.lastIndexOf(".")), params.data.main_image.rawFile)
            fetch(`/api/images/tiles/`, {
                method: "PUT",
                body
            }).catch(e => {
                throw e
            })
        }

        if(params.data.images){
            await Promise.all(params.data.images.map((img: any) => {
                const body  = new FormData();
                body.append(img.title, img.rawFile)
                return fetch(`/api/images/tiles/${params.data.id}`, {
                    method: "PUT",
                    body
                }).catch(e => {
                    throw e
                })
            }))
        }
        
        const res = await fetch(`/api/tile/${params.data.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params.data)
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status)
        }
        return {data: {id: params.data.id}}
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {

        params.data.materiale = params.data.materiale.id
        params.data.finishing = params.data.finishing.id

        updateImages(params.data.images, params.previousData.previous_images, {
            image: {path: '/api/images/tiles/', name: params.previousData.id},
            aux: `/api/images/tiles/${params.previousData.id}/`
        })

        await new Promise(res=> setTimeout(res, 500));

        delete params.data.images
        delete params.data.videos


        const res = await fetch(`/api/tile/${params.previousData.id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params.data)
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return {data: Object.assign(params.data, {id: params.previousData.id})}
    }
}