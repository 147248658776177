import React from "react"
import { AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, Edit, FileInput, FormDataConsumer, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"



export const BlockEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" label="Codice Blocco" required/>

            <ReferenceInput isRequired source="materiale.id" reference="material">
                <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ReferenceInput isRequired source="quality.id" reference="quality">
                <SelectInput label="Qualità" optionText="id"/>
            </ReferenceInput>
            
            <NumberInput required source="lung_A" label="Lunghezza A" />
            <NumberInput required source="lung_B" label="Lunghezza B" />
            <NumberInput required source="altezza" label="Altezza" />
            <NumberInput required source="larghezza" label="Larghezza" />
            <TextInput source="bancata" label="Bancata" />

            <ReferenceInput isRequired source="SR.id" reference="healthstate">
                <SelectInput label="Stato" optionText="description"/>
            </ReferenceInput>

            
            <BooleanInput source="show" label="Mostra al pubblico" />

            <NumberInput required source="prezzo" label="Prezzo" />

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} multiple source="images.aux">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
}