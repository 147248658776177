import * as React from "react";
import { Admin,  ListGuesser,  Resource } from 'react-admin';
import authProvider from "../providers/authProvider";
import provider from "../providers/provider";
import LoginPage from "./auth/LoginPage";
import { BlockCreate } from "./views/create/BlockCreate";
import { FinishingCreate } from "./views/create/FinishingCreate";
import { MaterialCreate } from "./views/create/MaterialCreate";
import { ProjectCreate } from "./views/create/ProjectCreate";
import { SlabCreate } from "./views/create/SlabCreate";
import { BlockList } from "./views/list/BlockList";
import { FinishingList } from "./views/list/FinishingList";
import { MaterialList } from "./views/list/MaterialList";
import { PackList } from "./views/list/PackList";
import { ProjectList } from "./views/list/ProjectList";
import { SlabList } from "./views/list/SlabList";
import { MaterialShow } from "./views/show/MaterialShow";
import { ProjectShow } from "./views/show/ProjectShow";

import GavelIcon from '@mui/icons-material/Gavel';
import ViewArrayIcon from '@mui/icons-material/ViewArray';
import BookIcon from '@mui/icons-material/Book';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TileIcon from '@mui/icons-material/Square';
import { FinishingEdit } from "./views/edit/FinishingEdit";
import { MaterialEdit } from "./views/edit/MaterialEdit";
import { SlabEdit } from "./views/edit/SlabEdit";
import { PackEdit } from "./views/edit/PackEdit";
import { ProjectEdit } from "./views/edit/ProjectEdit";
import { QualityList } from "./views/list/QualityList";
import { QualityCreate } from "./views/create/QualityCreate";
import { QualityEdit } from "./views/edit/QualityEdit";
import { HealthStateList } from "./views/list/HealthStateList";
import { HealthStateCreate } from "./views/create/HealthStateCreate";
import { HealthStateEdit } from "./views/edit/HealthStateEdit";
import { AppLayout } from "./AppLayout";
import { useLangContext } from "./LangContext";
import { NewsCreate } from "./views/create/NewsCreate";
import { NewsList } from "./views/list/NewsList";
import { NewsEdit } from "./views/edit/NewsEdit";
import { BlockEdit } from "./views/edit/BlockEdit";
import { TileList } from "./views/list/TileList";
import { TileCreate } from "./views/create/TileCreate";
import { TileEdit } from "./views/edit/TileEdit";
import { FloorList } from "./views/list/FloorList";
import { FloorCreate } from "./views/create/FloorCreate";
import { FloorEdit } from "./views/edit/FloorEdit";

const App = () => {

  const [lang, setLang] = React.useState("it")
  const langContext = useLangContext()
  langContext.lang = lang
  langContext.setLang = setLang
  langContext.getTranslation = (obj: {lang: string, description: string}[], dflt?: string) => {
    if(!Array.isArray(obj)) return dflt || "--nessuna traduzione--"
    const data = obj.filter(e => e.lang === lang)
    if(data.length > 0) return data[0].description || dflt || "--nessuna traduzione--"
    return dflt || "--nessuna traduzione--"
  }

  return <Admin 
    dataProvider={provider} 
    authProvider={authProvider}
    loginPage={LoginPage}
    layout={AppLayout}
    requireAuth
  >
      <Resource 
        icon={GavelIcon}
        name="finishing" 
        list={FinishingList} 
        create={FinishingCreate}
        edit={FinishingEdit}
      />
      <Resource 
        icon={GavelIcon}
        name="quality" 
        list={QualityList} 
        create={QualityCreate}
        edit={QualityEdit}
      />
      <Resource 
        icon={GavelIcon}
        name="healthstate" 
        list={HealthStateList} 
        create={HealthStateCreate}
        edit={HealthStateEdit}
      />
      <Resource 
        icon={BookIcon}
        name="material" 
        list={MaterialList}
        create={MaterialCreate}
        show={MaterialShow}
        edit={MaterialEdit}
      />
      <Resource      
        icon={ViewArrayIcon}
        name="block"
        list={BlockList}
        create={BlockCreate}
        edit={BlockEdit}
      />
      <Resource
        name="slab"
        list={SlabList}
        create={SlabCreate}
        edit={SlabEdit}
      />
      <Resource
        icon={ViewHeadlineIcon}
        name="pack"
        list={PackList}
        edit={PackEdit}
      />
      <Resource
        icon={AccountTreeIcon}
        name="project"
        list={ProjectList}
        create={ProjectCreate}
        show={ProjectShow}
        edit={ProjectEdit}
      />
      <Resource
        icon={ViewArrayIcon}
        name="news"
        list={NewsList}
        create={NewsCreate}
        edit={NewsEdit}
      />
      <Resource
        icon={TileIcon}
        name="tile"
        list={TileList}
        create={TileCreate}
        edit={TileEdit}
      />
      <Resource
        icon={TileIcon}
        name="floor"
        list={FloorList}
        create={FloorCreate}
        edit={FloorEdit}
      />
  </Admin>
};

export default App;
