import React, { useEffect, useState } from "react"
import { AutocompleteArrayInput, CheckboxGroupInput, Create, Edit, FileInput, ImageField, ImageInput, NumberInput, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"
import { RichTextInput } from 'ra-input-rich-text';
import { LangInput } from "../../LangInput";

export const MaterialEdit = (props: any) => {

    const [types, setTypes] = useState<{id: string, name: string}[]>([])

    useEffect(() => {
        fetch("/api/material/types").then(e => e.json()).then(e => {
            setTypes(e.map((f: any) => ({id: f.id, name: f.id})))
            console.log(e)
        })
    }, [])

    return <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" label="Nome" required/>

            <NumberInput source="ord_key" label="Order Key" defaultValue={1000}/>

            <LangInput source="name" label="Nome">
                <TextInput source="description"/>
            </LangInput>

            <LangInput source="commercial_name" label="Nome Commerciale">
                <TextInput source="description"/>
            </LangInput>

            <TextInput source="color" label="Colore" required/>

            <LangInput source="origin" label="Origine">
                <TextInput source="description"/>
            </LangInput>

            <AutocompleteArrayInput source='type' label="Famiglia" onCreate={(text) => {
                if(text){
                    setTypes([...types, {id: text, name: text}])
                    return {id: text, name: text}
                }
            }} choices={types}/>
            
            <LangInput source="description" label="Descrizione">
                <RichTextInput source="description" label="Descrizione"/>
            </LangInput>

            <LangInput source="available_finishings" label="Finiture">
                <TextInput source="description"/>
            </LangInput>

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.image" label="Immagine" >
                <ImageField source="src" title="title"/>
            </ImageInput>

            <ImageInput accept="image/*" multiple maxSize={20*1024*1024} source="images.aux" label="Immagini Ausiliarie" >
                <ImageField source="src" title="title"/>
            </ImageInput>

        </SimpleForm>
    </Edit>
}