import { AutocompleteInput, BooleanInput, Datagrid, EditButton, FunctionField, List, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput } from "react-admin";
import { CheckboxField } from "../../CheckboxField";
import { useLangContext } from "../../LangContext";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn />,
    <ReferenceInput isRequired source="q_=materiale__id" reference="material" alwaysOn perPage={100000}>
        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <TextInput label="Bancata" source="q_Lbancata" alwaysOn />,
]

export const TileList = (props: any) => {
    const lang = useLangContext();

    return <List {...props} filters={filters}>
        <Datagrid>
            <TextField source="id" label="Codice Lastra" />

            <TextField source="materiale.id" label="Materiale" />
            <TextField source="bancata" label="Bancata" />
            <TextField source="finishing.id" label="Finitura" />
            <TextField source="quality.id" label="Qualità" />

            <TextField source="lunghezza" label="Lunghezza" />
            <TextField source="larghezza" label="Larghezza" />
            <TextField source="spessore" label="Spessore" />

            <NumberField source="quantity" label="Disponibili"/>
            <NumberField source="selection" label="Selezione"/>

            <TextField source="floor.id" label="Cassa" />

            <CheckboxField source="isBevelled"/>
            <CheckboxField source="isFreeLength"/>

            <EditButton/>
        </Datagrid>
    </List>
}