import { UserMenu } from "react-admin"
import LogoutButton from "./auth/LogoutButton"
import { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';


export const AppUserMenu = () => {

    const [serverVersion, setServerVersion] = useState("--");

    useEffect(() => {
        fetch("/api/version").then(res => res.json()).then(data => setServerVersion(`${data.version} built ${data.build_time}`))
    }, [])

    return <UserMenu>
        <LogoutButton/>
        <MenuItem disabled>
            Server: {serverVersion}
        </MenuItem>
        <MenuItem disabled>
            Client: {process.env.REACT_APP_VERSION || "NONE"} built {process.env.REACT_APP_COMPILEDAT || "Jan-01-1970"}
        </MenuItem>
    </UserMenu>
}