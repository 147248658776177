import { AppBar } from "react-admin"
import { AppUserMenu } from "./UserMenu"
import { useLangContext } from "./LangContext"

import {Typography, Box} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';

export const AppAppBar = () => {
    const langContext = useLangContext()
    console.log(langContext.getTranslation)
    
    return <AppBar userMenu={<AppUserMenu/>}>
        <Typography
            variant="h6"
            color="inherit"
            id="react-admin-title"
        >
            Ziche Backoffice - 
        </Typography>
        <Box flex="1" />
        <FormControl size="small" sx={{ m: 1, minWidth: 200, color: "white !important" }}>
            <Select
                value={langContext.lang}
                label="Lingua di visualizzazione"
                onChange={(e) => {
                    langContext.setLang(e.target.value)
                }}
            >
                <MenuItem value={"it"}>Italiano</MenuItem>
                <MenuItem value={"en"}>Inglese</MenuItem>
                <MenuItem value={"fr"}>Francese</MenuItem>
                <MenuItem value={"es"}>Spagnolo</MenuItem>
                <MenuItem value={"ru"}>Russo</MenuItem>
                <MenuItem value={"zh"}>Cinese</MenuItem>
                <MenuItem value={"jp"}>Giapponese</MenuItem>
            </Select>
        </FormControl>
    </AppBar>
}