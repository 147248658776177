import React, { useRef, useState } from "react"
import { BooleanInput, CheckboxGroupInput, Create, ImageInput, FormDataConsumer, ImageField, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext, TextField, AutocompleteInput } from "react-admin"
import Typography from "@mui/material/Typography"

export const SlabCreate = (props: any) => {

    return <Create {...props}>
        <SimpleForm>
            <TextInput required source="block_id" label="Blocco" />

            <ReferenceInput isRequired source="materiale" reference="material">
                <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ReferenceInput isRequired source="finishing" reference="finishing">
                <AutocompleteInput label="Finitura" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <NumberInput required source="slab_no_start" label="Numero lastra" />

            <TextInput required source="position" label="Posizione" />
            <ReferenceInput isRequired source="quality" reference="quality">
                <SelectInput label="Qualità" optionText="id"/>
            </ReferenceInput>            
            <NumberInput required source="lung_A" label="Lunghezza A" />
            <NumberInput required source="lung_B" label="Lunghezza B" />
            <NumberInput required source="altezza" label="Altezza" />
            <NumberInput required source="spessore" label="Spessore" />
            <TextInput source="bancata" label="Bancata" />

            <ReferenceInput isRequired source="SR" reference="healthstate">
                <SelectInput label="Stato" optionText="description"/>
            </ReferenceInput>
            <BooleanInput source="show" label="Nascondi al pubblico" />

            <NumberInput required source="prezzo" label="Prezzo" />
            

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_ml">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_mlt">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_mlb">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_mr">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_mrb">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_mrt">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="image_vert">
                <ImageField source="src" title="title" />
            </ImageInput>

        </SimpleForm>
    </Create>
}