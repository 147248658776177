import React from "react"
import { AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, FileInput, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"



export const FloorCreate = (props: any) => {

    return <Create {...props}>
         <SimpleForm>
            <TextInput required source="id" label="ID" />
            <BooleanInput source="show" label="Nascondi al pubblico" />
            <NumberInput required source="price" label="Prezzo"/>
            <TextInput required source="position" label="Posizione"/>
            


            <ImageInput accept="image/*" maxSize={20*1024*1024} source="main_image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} multiple source="images">
                <ImageField source="src" title="title" />
            </ImageInput>

        </SimpleForm>
    </Create>
}