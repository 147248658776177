import { combineDataProviders } from 'react-admin';
import { blockProvider } from './blockProvider';
import { finishingProvider } from './finishingProvider';
import { materialProvider } from './materialProvider';
import { packProvider } from './packProvider';
import { projectProvider } from './projectProvider';
import { slabProvider } from './slabProvider';
import { qualityProvider } from './qualityProvider';
import { healthstateProvider } from './healthStateProvider';
import { newsProvider } from './news.provider';
import { tileProvider } from './tileProvider';
import { floorProvider } from './floorProvider';

export default combineDataProviders((resource) => {
    switch (resource) {
        case 'finishing':
            return finishingProvider
        case 'block':
                return blockProvider
        case 'material':
            return materialProvider
        case 'slab':
            return slabProvider
        case 'pack':
            return packProvider
        case 'project':
                return projectProvider
        case 'quality':
            return qualityProvider
        case 'healthstate':
            return healthstateProvider
        case 'news':
            return newsProvider
        case 'tile':
            return tileProvider
        case 'floor':
            return floorProvider
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});