import { Button, Datagrid, EditBase, EditButton, List, AutocompleteInput, ReferenceInput, SelectInput, ShowButton, TextField, TextInput, FunctionField } from "react-admin";
import { CheckboxField } from "../../CheckboxField";
import { useLangContext } from "../../LangContext";

const filters = [
    <ReferenceInput source="q_=blockId" reference="block" alwaysOn perPage={1000000}>
        <AutocompleteInput label="Blocco" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <ReferenceInput source="q_=materiale__id" reference="material" alwaysOn perPage={1000000}>
        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <ReferenceInput source="q_=finishing__id" reference="finishing" alwaysOn perPage={1000000}>
        <AutocompleteInput label="Finitura" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <TextInput label="Posizione" source="q_Lposition" alwaysOn />,
    <SelectInput source="q_=quality" label="Qualità" choices={[{id: "standard", name: "Standard"},{id: "extra", name: "Extra"},{id: "first choice", name: "Prima Scelta"},{id: "b line", name: "B-Line"}]} alwaysOn/>,
    <SelectInput source="q_=show" label="Mostra" choices={[{id: "bool:true", name: "SI"},{id: "bool:false", name: "NO"}]} alwaysOn/>
]

export const SlabList = (props: any) => {
    const lang = useLangContext()
    return <List {...props} filters={filters}>
        <Datagrid>
            
            <TextField source="id" label="Codice Lastra" />
            <TextField source="finishing.id" label="Finitura" />
            <TextField source="position" label="Posizione" />
            <FunctionField label="Qualità" render={(r: any) => lang.getTranslation(r.quality?.description)}/>
            <TextField source="blockId" label="Blocco" />
            <TextField source="materiale.id" label="Materiale" />

            <TextField source="lung_A" label="Lunghezza A" />
            <TextField source="lung_B" label="Lunghezza B" />
            <TextField source="altezza" label="Altezza" />
            <TextField source="spessore" label="Spessore" />
            <TextField source="bancata" label="Bancata" />

            <TextField source="SR.description" label="Difetti" />
            <FunctionField label="Prezzo" render={(record: any) => `${record.prezzo} €`} />
            <CheckboxField source="show"/>

            <EditButton/>
        </Datagrid>
    </List>
}