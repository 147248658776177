import { createContext, useContext } from "react"

export interface LangContext{
    lang: string,
    setLang: (lang: string) => any
    getTranslation: (obj: {lang: string, description: string}[], dflt?: string) => string
}

const langContext = createContext<LangContext>({
    lang: "it",
    setLang: () => {},
    getTranslation: () => ""
})

export const useLangContext = () =>  useContext(langContext);