import React from "react"
import { AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, FileInput, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"



export const TileCreate = (props: any) => {

    return <Create {...props}>
         <SimpleForm>
            <TextInput required source="id" label="ID" />

            <ReferenceInput isRequired source="materiale" reference="material">
                <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>
            <TextInput source="bancata" label="Bancata" defaultValue={"..."} />


            <ReferenceInput isRequired source="finishing" reference="finishing">
                <AutocompleteInput label="Finitura" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ReferenceInput isRequired source="quality" reference="quality">
                <AutocompleteInput label="Qualità" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <TextInput required source="selection" label="Selezione" />
            <NumberInput source="lunghezza" label="Lunghezza" />
            <NumberInput required source="larghezza" label="Larghezza" />
            <NumberInput required source="spessore" label="Spessore" />
            <NumberInput required source="quantity" label="Quantità"/>
            <NumberInput required source="mq" label="Metri quadri (MQ)" />


            <BooleanInput source="isBevelled" label="Bisellato" />
            <BooleanInput source="isFreeLength" label="A Correre" />

            <ReferenceInput isRequired source="floor" reference="floor">
                <AutocompleteInput label="Cassa" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="main_image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} multiple source="images">
                <ImageField source="src" title="title" />
            </ImageInput>

        </SimpleForm>
    </Create>
}