import React from "react"
import { AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, Edit, FileInput, FormDataConsumer, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"



export const TileEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm>
            <TextInput required source="id" label="ID" />

            <ReferenceInput isRequired source="materiale.id" reference="material">
                <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>
            <TextInput source="bancata" label="Bancata" defaultValue={"..."} />


            <ReferenceInput isRequired source="finishing.id" reference="finishing">
                <AutocompleteInput label="Finitura" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ReferenceInput isRequired source="quality.id" reference="quality">
                <AutocompleteInput label="Qualità" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <TextInput required source="selection" label="Selezione" />
            <NumberInput source="lunghezza" label="Lunghezza" />
            <NumberInput required source="larghezza" label="Larghezza" />
            <NumberInput required source="spessore" label="Spessore" />
            <NumberInput required source="quantity" label="Quantità"/>
            <NumberInput required source="mq" label="Metri quadri (MQ)" />

            <BooleanInput source="isBevelled" label="Bisellato" />
            <BooleanInput source="isFreeLength" label="A Correre" />

            <ReferenceInput isRequired source="floor" reference="floor">
                <AutocompleteInput label="Cassa" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} multiple source="images.aux">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
}