import { ArrayField, Button, ChipField, Datagrid, EditButton, List, ReferenceArrayField, ReferenceField, ShowButton, SimpleFormIterator, SingleFieldList, TextField, TextInput } from "react-admin";
import { ImageSourceField } from "../../ImageSource";
import { LangListField } from "../../LangListField";
import { StringArrayField } from "../../StringArrayField";
import { LangChipField } from "../../LangChipField";


export const NewsList = (props: any) => 
    <List {...props}>
        <Datagrid rowClick="edit">
            <LangListField source="title" label="Titolo"/>
            <TextField source='date'/>
            <StringArrayField source='tags'/>
            <ReferenceArrayField source='related' reference="news" label='Related'>
                <SingleFieldList>
                    <LangChipField source='title'/>
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton/>
        </Datagrid>
    </List>