import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');


export const newsProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {

        const res = await fetch(`/api/news/0`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title: JSON.stringify(params.data.title),
                tags:  params.data.tags,
                date:  params.data.date,
                description: JSON.stringify(params.data.description),
                related: params.data.related
            })
        })

        if(res.status !== 200) throw new Error(`http error: ${res.status}`)
        const {id} = await res.json()

        if(params.data.main_image){
            const body  = new FormData();
            body.append(id + params.data.main_image.title.substring(params.data.main_image.title.lastIndexOf(".")), params.data.main_image.rawFile)
            fetch(`/api/images/news/`, {
                method: "PUT",
                body
            }).catch(e => {
                throw e
            })
        }

        if(params.data.images){
            
            await Promise.all(params.data.images.map((img: any) => {
                const body  = new FormData();
                body.append(img.title, img.rawFile)
                return fetch(`/api/images/news/${id}`, {
                    method: "PUT",
                    body
                }).catch(e => {
                    throw e
                })
            }))
        }

        return {data: {id: params.data.id}}
    }
    ,
    update: async (a: any, params: any): Promise<UpdateResult<any>> => {

        updateImages(params.data.images, params.previousData.images, {
            image: {path: '/api/images/news/', name: params.previousData.id},
            aux: `/api/images/news/${params.previousData.id}/`
        })

        const res = await fetch(`/api/news/${params.previousData.id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title: JSON.stringify(params.data.title),
                tags:  params.data.tags,
                date:  params.data.date,
                description: JSON.stringify(params.data.description),
                related: params.data.related
            })
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return {data: {id: params.previousData.id}}
    }
}