import React, { useState } from "react"
import { AutocompleteInput, BooleanInput, Edit, FormDataConsumer, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList } from "react-admin"


export const PackEdit = (props: any) => {
    
    return <Edit {...props}>
        <SimpleForm>
            <ReferenceInput isRequired source="blockId" reference="block">
                <SelectInput disabled label="Blocco" optionText="id"/>
            </ReferenceInput>

            <ReferenceInput isRequired source="finishing" reference="finishing">
                <AutocompleteInput label="Finitura" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <TextInput required source="position" label="Posizione" />
            <SelectInput required source="quality" label="Qualità" choices={[{id: "standard", name: "Standard"},{id: "extra", name: "Extra"},{id: "first choice", name: "Prima Scelta"},{id: "b line", name: "B-Line"}]} />
        </SimpleForm>
    </Edit>
}