import { Datagrid, EditButton, List, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn />,
]

export const QualityList = (props: any) => 
    <List {...props} filters={filters}>
        <Datagrid>
        <TextField source="id" label="Nome" />
        <LangListField source="description"/>
        <EditButton/>
        </Datagrid>
    </List>