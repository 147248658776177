import { Button, Datagrid, List, ReferenceField, ShowButton, TextField, TextInput } from "react-admin";
import { ImageSourceField } from "../../ImageSource";
import { LangListField } from "../../LangListField";

const filters = [
    <TextInput label="Search" source="q_title" alwaysOn />,
]

export const ProjectList = (props: any) => 
    <List {...props} filters={filters}>
        <Datagrid rowClick="show">
            <ImageSourceField source="images.image" res="thumb" height="100px" label="Immagine"/>
            <LangListField source="title" label="Titolo"/>
            <LangListField source="location" label="Località"/>
            <LangListField source="nation" label="Nazione"/>
            <LangListField source="service" label="Service"/>
            <TextField source="ord_key"/>
        </Datagrid>
    </List>