import React from "react"
import { AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, Edit, FileInput, FormDataConsumer, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"



export const FloorEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm>
            <TextInput required source="id" label="ID" />
            <BooleanInput source="show" label="MOstra al pubblico" />
            <NumberInput required source="price" label="Prezzo"/>
            <TextInput required source="position" label="Posizione"/>

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} multiple source="images.aux">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
}