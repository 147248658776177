import { AutocompleteInput, BooleanInput, Datagrid, EditButton, FunctionField, List, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput } from "react-admin";
import { CheckboxField } from "../../CheckboxField";
import { useLangContext } from "../../LangContext";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn />,
    <ReferenceInput isRequired source="q_=materiale__id" reference="material" alwaysOn perPage={100000}>
        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <TextInput label="Bancata" source="q_Lbancata" alwaysOn />,
]

export const FloorList = (props: any) => {
    const lang = useLangContext();

    return <List {...props} filters={filters}>
        <Datagrid>
            <TextField source="id" label="Codice Lastra" />
            <TextField source="price" label="Prezzo" />
            <TextField source="position" label="Posizione" />
            <CheckboxField source="show"/>

            <EditButton/>
        </Datagrid>
    </List>
}