import { Create, SimpleForm, TextField, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"
import { RichTextInput } from "ra-input-rich-text"

export const QualityCreate = (props: any) => {

    return <Create {...props}>
        <SimpleForm>
        <TextInput source="id" required/>
        <LangInput source="description" label="Descrizione">
            <TextInput source="description" label="Descrizione"/>
        </LangInput>

        </SimpleForm>
    </Create>
}