import { ArrayField, ChipField, Datagrid, FunctionField, List, NumberField, ReferenceArrayField, ReferenceArrayInput, SingleFieldList, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";
import { StringArrayField } from "../../StringArrayField";
import { useLangContext } from "../../LangContext";
import { LangChipField } from "../../LangChipField";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn />,
    <TextInput label="Famiglia" source="q_Ltype" alwaysOn />,
    <TextInput label="Colore" source="q_Lcolor" alwaysOn />,
    <TextInput label="Origine" source="q_Lorigin" alwaysOn />,

]

export const MaterialList = (props: any) => {
    const lang = useLangContext();

    return <List {...props} filters={filters} >
        <Datagrid rowClick="show">
            <TextField source="id" label="Nome" />
            <StringArrayField source='type' label='Famiglia'/>
            <TextField source="color" label="Colore" />
            <LangListField source="origin" label="Origine" />
            
            <LangListField source="available_finishings" label="Finiture" />
            
            <NumberField source="ord_key" label="Indice di ordinamento"/>
        </Datagrid>
    </List>
}