import { Datagrid, EditButton, List, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn />,
]

export const FinishingList = (props: any) => 
    <List {...props} filters={filters}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <LangListField source="name"/>
            <EditButton/>
        </Datagrid>
    </List>