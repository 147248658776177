import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

function extension(name: string){
    return name.substring(name.lastIndexOf("."))
}

export const slabProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {


        if (!params.data.slab_no_end){
            params.data.slab_no_end = params.data.slab_no_start
        }
        
        await Promise.all(Array.from(Array(params.data.slab_no_end - params.data.slab_no_start + 1).keys()).map(i => {
            const slabid = `${params.data.block_id}-${i + params.data.slab_no_start}`
            const body  = new FormData();
            if(params.data.image) body.append(slabid + extension(params.data.image.title), params.data.image.rawFile)
            if(params.data.image_ml) body.append(slabid + ".ml" + extension(params.data.image_ml.title), params.data.image_ml.rawFile)
            if(params.data.image_mlt) body.append(slabid + ".mlt" + extension(params.data.image_mlt.title), params.data.image_mlt.rawFile)
            if(params.data.image_mlb) body.append(slabid + ".mlb" + extension(params.data.image_mlb.title), params.data.image_mlb.rawFile)
            if(params.data.image_mr) body.append(slabid + ".mr" + extension(params.data.image_mr.title), params.data.image_mr.rawFile)
            if(params.data.image_mrb) body.append(slabid + ".mrb" + extension(params.data.image_mrb.title), params.data.image_mrb.rawFile)
            if(params.data.image_mrt) body.append(slabid + ".mrt" + extension(params.data.image_mrt.title), params.data.image_mrt.rawFile)
            if(params.data.image_vert) body.append(slabid + ".vert" + extension(params.data.image_vert.title), params.data.image_vert.rawFile)

            if(Array.from(body.keys()).length > 0)        
                return fetch(`/api/images/packs/${params.data.block_id}/slabs/${slabid}/`, {
                    method: "PUT",
                    body
                }).catch(e => {
                    throw e
                })
        }))
        
        const res = await fetch(`/api/slab/${params.data.block_id}/${params.data.slab_no_start}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(Object.assign(params.data, {show: !params.data.show}))
        })

        if(res.status !== 200) throw new Error(`http error: ${res.status}`)


        return {data: {id: params.data.id}}
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {
        const slabid = `${params.previousData.blockId}-${params.previousData.numero_lastra}`
        await updateImages(params.data.images, params.previousData.images, {
            image: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: slabid},
            ml: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.ml`},
            mlb: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.mlb`},
            mlt: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.mlt`},
            mr: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.mr`},
            mrb: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.mrb`},
            mrt: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.mrt`},
            vert: {path: `/api/images/packs/${params.previousData.blockId}/slabs/${slabid}/`, name: `${slabid}.vert`},
        })
        await new Promise(res=> setTimeout(res, 500));

        const res = await fetch(`/api/slab/${params.previousData.blockId}/${params.previousData.numero_lastra}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                finishing: params.data.finishing.id,
                position: params.data.position,
                materiale: params.data.materiale.id,
                quality: params.data.quality.id,
                lung_A: parseFloat(params.data.lung_A),
                lung_B: parseFloat(params.data.lung_B),
                altezza: parseFloat(params.data.altezza),
                spessore: parseFloat(params.data.spessore),
                SR: params.data.SR.id,
                show: params.data.show,
                bancata: params.data.bancata,
                prezzo: parseFloat(params.data.prezzo)
            })
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return {data: {id: params.previousData.id}}
    }
}