import React from "react"
import { AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, FileInput, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList, useRecordContext } from "react-admin"



export const BlockCreate = (props: any) => {

    return <Create {...props}>
         <SimpleForm>
            <TextInput required source="id" label="ID Blocco" />

            <ReferenceInput isRequired source="materiale" reference="material">
                <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>


            <TextInput required source="position" label="Posizione" />
            <ReferenceInput isRequired source="quality" reference="quality">
                <SelectInput label="Qualità" optionText="id"/>
            </ReferenceInput>            
            <NumberInput required source="lung_A" label="Lunghezza A" />
            <NumberInput required source="lung_B" label="Lunghezza B" />
            <NumberInput required source="altezza" label="Altezza" />
            <NumberInput required source="larghezza" label="Larghezza" />
            <TextInput source="bancata" label="Bancata" />

            <ReferenceInput isRequired source="SR" reference="healthstate">
                <SelectInput label="Stato" optionText="description"/>
            </ReferenceInput>
            <BooleanInput source="show" label="Nascondi al pubblico" />

            <NumberInput required source="prezzo" label="Prezzo" />
            

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="main_image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} multiple source="images">
                <ImageField source="src" title="title" />
            </ImageInput>

        </SimpleForm>
    </Create>
}