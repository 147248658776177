import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, HttpError, UpdateResult } from 'react-admin';

const base_provider = jsonServerProvider('/api');


export const packProvider = {
    ...base_provider,
    update: async (a: any, params: any): Promise<UpdateResult<any>> => {
        const res = await fetch(`/api/pack/${params.previousData.id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                blockId: params.data.blockId,
                finishingId: params.data.finishingId,
                position: params.data.position,
                quality: params.data.quality
            })
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return {data: {id: params.previousData.id}}
    }
}