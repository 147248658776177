import { Create, SimpleForm, TextInput } from "react-admin"

export const HealthStateCreate = (props: any) => {

    return <Create {...props}>
        <SimpleForm>
        <TextInput source="id" required/>
        <TextInput source="description" required/>
        </SimpleForm>
    </Create>
}