import React, { useState } from "react"
import { AutocompleteInput, BooleanInput, Edit, FormDataConsumer, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput, useGetList } from "react-admin"


export const SlabEdit = (props: any) => {
    const {data: blocks, isLoading: isBlocksLoading} = useGetList( 'block',
        { 
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' }
        }
    );
    
    return <Edit {...props}>
        <SimpleForm>
            <ReferenceInput isRequired source="blockId" reference="block" alwaysOn perPage={1000000}>
                <AutocompleteInput label="Blocco" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ReferenceInput isRequired source="materiale.id" reference="material">
                <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
            </ReferenceInput>

            <ReferenceInput isRequired source="finishing.id" reference="finishing">
                <SelectInput label="Finitura" optionText={"id"} />
            </ReferenceInput>

            <TextInput disabled required source="numero_lastra" label="Numero lastra" />
            <TextInput required source="position" label="Posizione" />
            
            <ReferenceInput isRequired source="quality.id" reference="quality">
                <SelectInput label="Qualità" optionText="id"/>
            </ReferenceInput>
            
            <NumberInput required source="lung_A" label="Lunghezza A" />
            <NumberInput required source="lung_B" label="Lunghezza B" />
            <NumberInput required source="altezza" label="Altezza" />
            <NumberInput required source="spessore" label="Spessore" />
            <TextInput source="bancata" label="Bancata" />

            <ReferenceInput isRequired source="SR.id" reference="healthstate">
                <SelectInput label="Stato" optionText="description"/>
            </ReferenceInput>

            
            <BooleanInput source="show" label="Mostra al pubblico" />

            <NumberInput required source="prezzo" label="Prezzo" />

            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.ml">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.mlt">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.mlb">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.mr">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.mrb">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.mrt">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput accept="image/*" maxSize={20*1024*1024} source="images.vert">
                <ImageField source="src" title="title" />
            </ImageInput>

        </SimpleForm>
    </Edit>
}