import * as React from 'react';
import { useLogin, useNotify } from 'react-admin';
import {
    Box,
    Button,
    CssBaseline,
    Grid,
    Paper,
    Typography,
  } from "@mui/material";
import "./login.css"

const LoginPage = ({ theme }: any) => {
    const login = useLogin();
    const notify = useNotify();
    const [bgs, setbgs] = React.useState<string[]>([])
    const [bg, setBg] = React.useState(0)

    React.useEffect(() => {
      fetch("/api/project/random").then(e => e.json()).then(e => {
        setbgs([e.images.image.src, ...e.images.aux.map((e: any) => e.src)])
      })

      const i = setInterval(() => {
        setBg(b => b+1)
        console.log(bgs, `url(${bgs.length > 0 ? bgs[bg % bgs.length] : ""})`)
      }, 20000)

      return () => clearInterval(i)
    }, [])

    return (
        <Grid container component="main" className="gridFullHeight">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className="loginImageBackground" style={{
          backgroundImage: `url(${bgs.length > 0 ? bgs[bg % bgs.length] : ""})`
        }}>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} className="loginInteractionBoxContainer">
          <Box className="loginInteractionBox">
            <div className='logo'/>
            <Typography variant="h6">Please Login</Typography>
            <Button variant="contained" onClick={(e: any) => {
                e.preventDefault();
                login({}).catch((e) => notify(e.toString()));
            }}>Login</Button>
          </Box>
        </Grid>
      </Grid>
    );
};

export default LoginPage;