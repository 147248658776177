import { RichTextInput } from "ra-input-rich-text"
import { ArrayInput, SelectInput, SimpleFormIterator, TextInput, useRecordContext } from "react-admin"

export const LangInput = ({source, label, langs, children}: any) => {
    return <ArrayInput source={source} required label={label}>
        <SimpleFormIterator inline>
            <SelectInput source="lang" choices={langs ||[
                { id: 'it', name: 'Italiano' },
                { id: 'en', name: 'Inglese' },
                { id: 'de', name: 'Tedesco' },
                { id: 'fr', name: 'Francese' },
                { id: 'ru', name: 'Russo' },
                { id: 'es', name: 'Spagnolo' },
            ]}/>
            {children}
         </SimpleFormIterator>
    </ArrayInput>
}