import { ArrayField, ChipField, Datagrid, FunctionField, ReferenceField, RichTextField, Show, ShowButton, SimpleForm, SingleFieldList, TextField, useRecordContext } from "react-admin";
import { ImageAuxSourceField, ImageSourceField } from "../../ImageSource";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { LangField } from "../../LangField";
import { useLangContext } from "../../LangContext";
import { LangListField } from "../../LangListField";
import { MapField } from "../../MapField";

export const ProjectShow = () => {
    const langContext = useLangContext()

    return <Show>
        <Container maxWidth="xl" style={{padding: "3rem"}}>
            <Grid container>
                <Grid item xs={12} md={8} lg={9}>
                    <Stack>
                        <FunctionField source="title" variant="h4" render={(record: any) => langContext.getTranslation(record.title)} />

                        <div><FunctionField variant="overline" render={(record: any) => langContext.getTranslation(record.location)}/> - <FunctionField variant="overline" render={(record: any) => langContext.getTranslation(record.nation)}/></div>


                        <Typography variant='h5' style={{paddingTop: '3rem'}}>Descrizione</Typography>
                        <LangListField variant="body2" source="description"/>

                        <Typography variant='h5' style={{paddingTop: '3rem'}}>Materiali</Typography>
                        <ArrayField source="materials">
                            <Datagrid>
                                <TextField source="material.id"/>
                                <FunctionField source="amount" label="Quantità" render={(record: any) => record.amount + " mq"} />
                                <ReferenceField source="material.id" reference="material">
                                    <ShowButton/>
                                </ReferenceField>
                            </Datagrid>
                        </ArrayField>

                        <MapField source='coordinates'/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <ImageSourceField source={"images.image"} res={"src"} width={"100%"}/>
                    <ImageAuxSourceField source={"images.aux"} res={"src"} width={"100%"}/>
                </Grid>
            </Grid>
            
        </Container>
    </Show>
};