import { AutocompleteInput, BooleanInput, Datagrid, EditButton, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput } from "react-admin";
import { CheckboxField } from "../../CheckboxField";
import { useLangContext } from "../../LangContext";

const filters = [
    <TextInput label="Search" source="q_Lid" alwaysOn />,
    <ReferenceInput isRequired source="q_=materiale__id" reference="material" alwaysOn perPage={100000}>
        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <TextInput label="Bancata" source="q_Lbancata" alwaysOn />,
]

export const BlockList = (props: any) => {
    const lang = useLangContext();

    return <List {...props} filters={filters}>
        <Datagrid>
            <TextField source="id" label="Codice Lastra" />
            <TextField source="position" label="Posizione" />
            <TextField source="materiale.id" label="Materiale" />

            <TextField source="lung_A" label="Lunghezza A" />
            <TextField source="lung_B" label="Lunghezza B" />
            <TextField source="altezza" label="Altezza" />
            <TextField source="larghezza" label="Larghezza" />
            <TextField source="bancata" label="Bancata" />

            <TextField source="SR.description" label="Difetti" />
            <FunctionField label="Prezzo" render={(record: any) => `${record.prezzo} €`} />
            <CheckboxField source="show"/>

            <EditButton/>
        </Datagrid>
    </List>
}