import { useRecordContext } from "react-admin"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import React from "react";

export const ImageAuxSourceField = (props: {source: string, res: "src" | "thumb", noImage?: any, [k: string]: any}) => {
    
    const record = useRecordContext()
    const path = props.source.split(".")
    const images = path.reduce((o, p) => o && o[p], record)
    
    return <div>
        {images && <ImageList variant="masonry" cols={3} gap={8}>
                        {Object.keys(images).filter((e: string) => !!images[e][props.res]).map((key: string) => (
                            <ImageListItem key={key}>
                                <img
                                    src={images[key][props.res]}
                                    alt={key}
                                    />
                            </ImageListItem>
                        ))}
                    </ImageList>}
        {!images && (!!props.noImage ? props.noImage : <p>No image available</p>)}
    </div>
}


export const ImageSourceField = (props: {source: string, res: "src" | "thumb", noImage?: any, [k: string]: any}) => {
    
    const record = useRecordContext()
    const path = props.source.split(".")
    const image = path.reduce((o, p) => o && o[p], record)
    
    return <div>
        {(image && image[props.res]) ? 
                    <img {...props} src={image[props.res]} alt={props.source}/> : 
                    (!!props.noImage ? props.noImage : <p>No image available</p>)
        }
    </div>
}