import { Button, Datagrid, EditButton, FunctionField, List, AutocompleteInput, ReferenceInput, SelectInput, ShowButton, TextField, TextInput, useGetOne, useRecordContext } from "react-admin";
import { ImageSourceField } from "../../ImageSource";
import { CheckboxField } from "../../CheckboxField";

const filters: any[] = [
    <ReferenceInput source="q_=blockId" reference="block" alwaysOn perPage={1000000}>
        <AutocompleteInput label="Blocco" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <ReferenceInput source="q_=material" reference="material" alwaysOn perPage={1000000}>
        <AutocompleteInput label="Materiale" optionText="id" filterToQuery={(q) => ({q_Lid: q})}/>
    </ReferenceInput>,
    <SelectInput source="q_=show" label="Mostra" choices={[{id: "bool:true", name: "SI"},{id: "bool:false", name: "NO"}]} alwaysOn/>
]

export const PackList = (props: any) => 
    <List {...props} filters={filters}>
        <Datagrid>
            <ImageSourceField source="images.image" res="thumb" height="100px"/>
            <TextField source="blockId" label="Blocco"/>
            <TextField source="material" label="Materiale"/>
            <FunctionField label="Lastre" render={(record: any) => `${record.from_slab}-${record.to_slab} (${record.cardinality})`}/>
            <CheckboxField source="show"/>
            <EditButton/>
        </Datagrid>
    </List>