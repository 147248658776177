import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const materialProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {
                
        if(params.data.main_image){
            const body  = new FormData();
            body.append(params.data.id + params.data.main_image.title.substring(params.data.main_image.title.lastIndexOf(".")), params.data.main_image.rawFile)
            fetch(`/api/images/materials/`, {
                method: "PUT",
                body
            }).catch(e => {
                throw e
            })
        }

        if(params.data.images){
            await Promise.all(params.data.images.map((img: any) => {
                const body  = new FormData();
                body.append(img.title, img.rawFile)
                return fetch(`/api/images/materials/${params.data.id}`, {
                    method: "PUT",
                    body
                }).catch(e => {
                    throw e
                })
            }))
        }

        const res = await fetch(`/api/material/${params.data.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                color: params.data.color,
                origin: JSON.stringify(params.data.origin),
                name: JSON.stringify(params.data.name),
                commercial_name: JSON.stringify(params.data.commercial_name),
                description: JSON.stringify(params.data.description),
                type: params.data.type,
                ord_key: params.data.ord_key,
                available_finishings: JSON.stringify(params.data.available_finishings)
            })
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return {data: {id: params.data.id}}
    },

    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {

        updateImages(params.data.images, params.previousData.previous_images, {
            image: {path: '/api/images/materials/', name: params.previousData.id},
            aux: `/api/images/materials/${params.previousData.id}/`
        })

        const res = await fetch(`/api/material/${params.previousData.id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: params.data.id,
                color: params.data.color,
                origin: JSON.stringify(params.data.origin),
                name: JSON.stringify(params.data.name),
                commercial_name: JSON.stringify(params.data.commercial_name),
                description: JSON.stringify(params.data.description),
                type: params.data.type,
                ord_key: params.data.ord_key,
                available_finishings: JSON.stringify(params.data.available_finishings)
            })
        })

        if(res.status !== 200){
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return {data: {id: params.previousData.id}}
    }
}